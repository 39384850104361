<template>
  <div class="w-full">
    <Dialog
      header="Header"
      :visible="true"
      :showHeader="false"
      :modal="true"
      :closeOnEscape="false"
      :closable="false"
    >
      <div class="m-5 justify-content-center">
        <span>Se esta cerrando la sesión, por favor espere...
        </span>
        <ProgressBar
          mode="indeterminate"
          style="height: 0.5em"
          class="m-2 w-full"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import ProgressBar from "primevue/progressbar";
export default {
  components: {
    ProgressBar
  },
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout")
      .then(() => {
        //this.$router.go();
        //this.$router.push({ name: "login" });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.$router.go();
      });
    }
  },
};
</script>
